/**
 * @generated SignedSource<<3482c15b4a58b6da24093c9f4f95260b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type productTaxFreeColumn_ProductFragment$data = {
  readonly isTaxFree?: boolean;
  readonly " $fragmentType": "productTaxFreeColumn_ProductFragment";
};
export type productTaxFreeColumn_ProductFragment$key = {
  readonly " $data"?: productTaxFreeColumn_ProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"productTaxFreeColumn_ProductFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isTaxFree",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "productTaxFreeColumn_ProductFragment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "AccountGroupProduct",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "PlacementProduct",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "LicenseProduct",
      "abstractKey": null
    }
  ],
  "type": "Product",
  "abstractKey": "__isProduct"
};
})();

(node as any).hash = "f6c6a05805c5bc415ad345475d083cd5";

export default node;
