/**
 * @generated SignedSource<<959864aa142da2a359066296f009d3f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type licensesTable_AccountFragment$data = {
  readonly extension: {
    readonly licenses?: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"licensesTable_LicenseFragment">;
        };
      } | null | undefined> | null | undefined;
    };
  };
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "licensesTable_AccountFragment";
};
export type licensesTable_AccountFragment$key = {
  readonly " $data"?: licensesTable_AccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"licensesTable_AccountFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "licensesTable_AccountFragment"
};

(node as any).hash = "51d4d16334dcac69b6bde17b6b90ebeb";

export default node;
