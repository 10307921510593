import { type PathParams } from "@thekeytechnology/epic-ui";
import React, { useMemo } from "react";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { type coachingEdit_CoachingOfferFragment$key } from "@relay/coachingEdit_CoachingOfferFragment.graphql";
import { type coachingEdit_EditAssociatedCoachesMutation } from "@relay/coachingEdit_EditAssociatedCoachesMutation.graphql";
import { type coachingEdit_EditCoachingOfferParticipationCertificateMutation } from "@relay/coachingEdit_EditCoachingOfferParticipationCertificateMutation.graphql";
import { type coachingEdit_EditCoachingRemunerationMutation } from "@relay/coachingEdit_EditCoachingRemunerationMutation.graphql";
import { type coachingEdit_EditGroupSettingMutation } from "@relay/coachingEdit_EditGroupSettingMutation.graphql";
import { type coachingEdit_EditOfferDescriptionMutation } from "@relay/coachingEdit_EditOfferDescriptionMutation.graphql";
import { type coachingEdit_Query } from "@relay/coachingEdit_Query.graphql";
import { ParticipationCertificateForm } from "@screens/coaching-edit/parts/participation-certificate-form";
import type { EditCoachingOfferParticipationCertificateFormState } from "@screens/coaching-edit/parts/participation-certificate-form";
import { VisibilityCoachingOfferConfigEditor } from "@screens/offers/parts/visibility-coaching-offer-config-editor/visibility-coaching-offer-config-editor.component";
import {
	COACHING_OFFER_FRAGMENT,
	EDIT_ASSOCIATED_COACHES_MUTATION,
	EDIT_COACHING_REMUNERATION_MUTATION,
	EDIT_GROUP_SETTING_MUTATION,
	EDIT_OFFER_DESCRIPTION_MUTATION,
	EDIT_PARTICIPATION_CERTIFICATE_REWARD_MUTATION,
	QUERY,
} from "./coaching-edit.graphql";
import { type CoachingsPath } from "./coaching-edit.paths";
import { type FileV2 } from "../../features/files/file-selection-field";
import { BaseScreen } from "../BaseScreen";
import { EditAssociatedCoachesForm } from "../offers/parts/edit-associated-coaches-form";
import { type EditAssociatedCoachesFormState } from "../offers/parts/edit-associated-coaches-form/edit-associated-coaches-form.types";
import { EditCoachingOfferForm } from "../offers/parts/edit-coaching-offer-form";
import { type EditCoachingOfferFormState } from "../offers/parts/edit-coaching-offer-form/edit-coaching-offer-form.types";
import { EditCoachingRemunerationForm } from "../offers/parts/edit-coaching-remuneration-form/edit-coaching-remuneration-form.component";
import { type EditCoachingRemunerationFormState } from "../offers/parts/edit-coaching-remuneration-form/edit-coaching-remuneration-form.types";
import { EditGroupSettingForm } from "../offers/parts/edit-group-setting-form";
import { type EditGroupSettingFormState } from "../offers/parts/edit-group-setting-form/edit-group-setting-form.types";

export const CoachingEditScreen = () => {
	const { coachingId } = useParams<PathParams<typeof CoachingsPath>>();

	const query = useLazyLoadQuery<coachingEdit_Query>(QUERY, {
		id: coachingId!,
	});
	const [editOfferDescription] = useMutation<coachingEdit_EditOfferDescriptionMutation>(
		EDIT_OFFER_DESCRIPTION_MUTATION,
	);
	const [editAssociatedCoaches] = useMutation<coachingEdit_EditAssociatedCoachesMutation>(
		EDIT_ASSOCIATED_COACHES_MUTATION,
	);
	const [editGroupSetting] = useMutation<coachingEdit_EditGroupSettingMutation>(
		EDIT_GROUP_SETTING_MUTATION,
	);
	const [editCoachingRemuneration] = useMutation<coachingEdit_EditCoachingRemunerationMutation>(
		EDIT_COACHING_REMUNERATION_MUTATION,
	);
	const [editParticipationCertificate] =
		useMutation<coachingEdit_EditCoachingOfferParticipationCertificateMutation>(
			EDIT_PARTICIPATION_CERTIFICATE_REWARD_MUTATION,
		);
	const offerWrapper = useFragment<coachingEdit_CoachingOfferFragment$key>(
		COACHING_OFFER_FRAGMENT,
		query.Admin.Coaching.GetCoachingOffer ?? null,
	);
	const hasCertificate = useMemo(() => !!offerWrapper?.participationCertificate, [offerWrapper]);

	const handleDescriptionOnSubmit = (values: EditCoachingOfferFormState) => {
		editOfferDescription({
			variables: {
				input: {
					id: offerWrapper?.id!,
					description: {
						description: values.description,
						tagIds: values.tags?.map((t) => t.id) || [],
						imageId: values.image?.id,
						shortDescription: values.shortDescription,
						name: values.name,
						contractPartnerId: values.contractPartner?.id,
						licenseProductId: values.licenseProduct?.id,
						modules: values.modules,
						contents: values.contents,
					},
				},
			},
			onCompleted: () => {
				toast.success("Änderungen gespeichert.");
			},
			onError: () => {
				toast.error("Änderungen konnten nicht gespeichert werden.");
			},
		});
	};
	const handleOnAssociatedCoachesSubmit = (values: EditAssociatedCoachesFormState) => {
		editAssociatedCoaches({
			variables: {
				input: {
					potentialCancellationMessage:
						"Das Coaching wurde abgesagt, da der bisherige Coach vom coaching entfernt wurde.",
					id: offerWrapper?.id!,
					coachIds: values.coaches?.map((e) => e.coach.id) ?? [],
				},
			},
			onCompleted: () => {
				toast.success("Coaches gespeichert.");
			},
			onError: () => {
				toast.error("Fehler aufgetreten beim speichern der Coaches.");
			},
		});
	};

	const handleGroupSettingOnSubmit = (values: EditGroupSettingFormState) => {
		editGroupSetting({
			variables: {
				input: {
					id: offerWrapper?.id!,
					setting: {
						kind: values.kind,
						maxParticipants: values.maxParticipants,
					},
				},
			},
			onCompleted: () => {
				toast.success("Änderungen gespeichert.");
			},
			onError: () => {
				toast.error("Coachingtyp im Nachhinein nicht änderbar!");
			},
		});
	};

	const handleCoachingRemuneration = (values: EditCoachingRemunerationFormState) => {
		editCoachingRemuneration({
			variables: {
				input: {
					id: offerWrapper?.id!,
					sessionRate: values.sessionRate,
					percentageShare: values.percentageShare,
					additionalInformation: values.additionalInformation,
				},
			},
			onCompleted: () => {
				toast.success("Änderungen gespeichert.");
			},
			onError: () => {
				toast.error("Fehler aufgetreten beim Speichern des Stundensatzes");
			},
		});
	};

	const handleParticipationCertificateRewardOnSubmit = (
		values: EditCoachingOfferParticipationCertificateFormState,
	) => {
		editParticipationCertificate({
			variables: {
				input: {
					coachingOfferId: offerWrapper?.id!,
					participationCertificateOpt:
						{
							variables: values.variables,
							name: values.name,
							html: values.html,
						} ?? undefined,
				},
			},
			onCompleted: () => {
				toast.success("Änderungen gespeichert.");
			},
			onError: () => {
				toast.error("Fehler aufgetreten beim Speichern des Teilnahmezertifikates");
			},
		});
	};

	const handleParticipationCertificateOnCreate = () => {
		editParticipationCertificate({
			variables: {
				input: {
					coachingOfferId: offerWrapper!.id!,
					participationCertificateOpt: {
						variables: [],
						name: "",
						html: "",
					},
				},
			},
			onCompleted: () => {
				toast.success("Änderungen gespeichert.");
			},
			onError: () => {
				toast.error("Fehler aufgetreten beim Erstellen des Teilnahmezertifikates");
			},
		});
	};
	const handleParticipationCertificateOnDelete = () => {
		editParticipationCertificate({
			variables: {
				input: {
					coachingOfferId: offerWrapper!.id!,
					participationCertificateOpt: undefined,
				},
			},
			onCompleted: () => {
				toast.success("Änderungen gespeichert.");
			},
			onError: () => {
				toast.error("Fehler aufgetreten beim Löschen des Teilnahmezertifikates");
			},
		});
	};

	if (!offerWrapper) return <BaseScreen>Angebot nicht gefunden.</BaseScreen>;
	const coachingOfferInitialState: EditCoachingOfferFormState = {
		tags:
			offerWrapper.description?.tags?.map((t) => ({
				id: t.id,
				data: {
					name: t.data.name,
				},
			})) ?? [],
		name: offerWrapper.description?.name ?? "",
		shortDescription: offerWrapper.description?.shortDescription ?? "",
		contents: offerWrapper.description?.contents ?? "",
		modules: offerWrapper.description?.modules ?? "",
		image: offerWrapper.description?.image?.id
			? ({
					id: offerWrapper.description?.image?.id,
					name: offerWrapper.description?.image?.name,
			  } satisfies FileV2)
			: undefined,
		contractPartner: offerWrapper.description?.contractPartner
			? {
					id: offerWrapper.description?.contractPartner?.id,
					name: offerWrapper.description?.contractPartner?.data.name,
			  }
			: undefined,
		description: offerWrapper.description?.description ?? "",
		licenseProduct: offerWrapper.description?.licenseProduct ?? undefined,
	};
	const groupSettingInitialState: EditGroupSettingFormState = {
		kind: offerWrapper.setting.kind,
		maxParticipants: offerWrapper.setting?.maxParticipants ?? 1,
	};
	const associatedCoachesInitialState: EditAssociatedCoachesFormState = {
		coaches:
			offerWrapper?.associatedCoaches?.map((c) => ({
				id: c.id,
				coach: { id: c.coach!.id, name: c.coach!.name },
			})) ?? [],
	};
	const coachingRemunerationInitialState: EditCoachingRemunerationFormState = {
		sessionRate: offerWrapper.remunerationData.sessionRate ?? {
			grossPrice: 0,
			netPrice: 0,
			taxRatePercentage: 0,
		},
		percentageShare: offerWrapper.remunerationData.percentageShare,
		additionalInformation: offerWrapper.remunerationData.additionalInformation,
	};
	const participationCertificateInitialState: EditCoachingOfferParticipationCertificateFormState =
		{
			name: offerWrapper.participationCertificate?.name ?? "",
			html: offerWrapper.participationCertificate?.html ?? "",
			variables: [...(offerWrapper.participationCertificate?.variables ?? [])],
		};

	return (
		<BaseScreen>
			<h1>Coaching anpassen</h1>
			{offerWrapper && (
				<React.Fragment>
					<EditCoachingOfferForm
						queryFragmentRef={query}
						initialState={coachingOfferInitialState}
						onSubmit={handleDescriptionOnSubmit}
					/>
					<VisibilityCoachingOfferConfigEditor coachingOfferFragmentRef={offerWrapper} />
					<EditCoachingRemunerationForm
						initialState={coachingRemunerationInitialState}
						onSubmit={handleCoachingRemuneration}
					></EditCoachingRemunerationForm>
					<EditAssociatedCoachesForm
						queryFragmentRef={query}
						initialState={associatedCoachesInitialState}
						onSubmit={handleOnAssociatedCoachesSubmit}
					/>
					<EditGroupSettingForm
						initialState={groupSettingInitialState}
						onSubmit={handleGroupSettingOnSubmit}
					/>
					<ParticipationCertificateForm
						initialState={participationCertificateInitialState}
						onSubmit={handleParticipationCertificateRewardOnSubmit}
						variables={[...(offerWrapper.participationCertificate?.variables ?? [])]}
						onCreate={handleParticipationCertificateOnCreate}
						onDelete={handleParticipationCertificateOnDelete}
						hasCertificate={hasCertificate}
					/>
				</React.Fragment>
			)}
		</BaseScreen>
	);
};
