/**
 * @generated SignedSource<<0d546b55c7438e19c53dbec1ef7e7976>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditPlacementProductInput = {
  clientMutationId?: string | null | undefined;
  costUnit?: string | null | undefined;
  id: string;
  isTaxFree: boolean;
  netPrice: any;
  title: string;
};
export type placementProductForm_EditPlacementProductMutation$variables = {
  input: EditPlacementProductInput;
};
export type placementProductForm_EditPlacementProductMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly editPlacementProduct: {
        readonly product: {
          readonly " $fragmentSpreads": FragmentRefs<"placementProductForm_PlacementProductFragment">;
        };
      } | null | undefined;
    };
  };
};
export type placementProductForm_EditPlacementProductMutation = {
  response: placementProductForm_EditPlacementProductMutation$data;
  variables: placementProductForm_EditPlacementProductMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "placementProductForm_EditPlacementProductMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditPlacementProductPayload",
                "kind": "LinkedField",
                "name": "editPlacementProduct",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PlacementProduct",
                    "kind": "LinkedField",
                    "name": "product",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "placementProductForm_PlacementProductFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "placementProductForm_EditPlacementProductMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditPlacementProductPayload",
                "kind": "LinkedField",
                "name": "editPlacementProduct",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PlacementProduct",
                    "kind": "LinkedField",
                    "name": "product",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isHidden",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isTaxFree",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "netPrice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "costUnit",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6e265ba993038ebc95bbf828ee789e58",
    "id": null,
    "metadata": {},
    "name": "placementProductForm_EditPlacementProductMutation",
    "operationKind": "mutation",
    "text": "mutation placementProductForm_EditPlacementProductMutation(\n  $input: EditPlacementProductInput!\n) {\n  Admin {\n    Billing {\n      editPlacementProduct(input: $input) {\n        product {\n          ...placementProductForm_PlacementProductFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment placementProductForm_PlacementProductFragment on PlacementProduct {\n  id\n  title\n  isHidden\n  isTaxFree\n  netPrice\n  costUnit\n}\n"
  }
};
})();

(node as any).hash = "ec3063c8ff1a474b655022fcaf4ff1a8";

export default node;
