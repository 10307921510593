/**
 * @generated SignedSource<<649d565aacbf5e6999a681daae5d4781>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type placementProductForm_PlacementProductFragment$data = {
  readonly costUnit: string | null | undefined;
  readonly id: string;
  readonly isHidden: boolean;
  readonly isTaxFree: boolean;
  readonly netPrice: any;
  readonly title: string;
  readonly " $fragmentType": "placementProductForm_PlacementProductFragment";
};
export type placementProductForm_PlacementProductFragment$key = {
  readonly " $data"?: placementProductForm_PlacementProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"placementProductForm_PlacementProductFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "placementProductForm_PlacementProductFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isHidden",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTaxFree",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "netPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costUnit",
      "storageKey": null
    }
  ],
  "type": "PlacementProduct",
  "abstractKey": null
};

(node as any).hash = "b75f84f52f81a82b1cdd1fe41d4b5973";

export default node;
