import graphql from "babel-plugin-relay/macro";

export const PRODUCT_FRAGMENT = graphql`
	fragment productTaxFreeColumn_ProductFragment on Product {
		... on AccountGroupProduct {
			isTaxFree
		}
		... on PlacementProduct {
			isTaxFree
		}
		... on LicenseProduct {
			isTaxFree
		}
	}
`;
