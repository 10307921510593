import graphql from "babel-plugin-relay/macro";

export const PLACEMENT_PRODUCT_FRAGMENT = graphql`
	fragment placementProductForm_PlacementProductFragment on PlacementProduct {
		id
		title
		isHidden
		isTaxFree
		netPrice
		costUnit
	}
`;

export const EDIT_PLACEMENT_PRODUCT_MUTATION = graphql`
	mutation placementProductForm_EditPlacementProductMutation($input: EditPlacementProductInput!) {
		Admin {
			Billing {
				editPlacementProduct(input: $input) {
					product {
						...placementProductForm_PlacementProductFragment
					}
				}
			}
		}
	}
`;
