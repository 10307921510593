/**
 * @generated SignedSource<<683c12f29f1a08480f4c982dc1df70f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Branch = "adviceAndConsulting" | "analysisAndStatistic" | "financeAccountingAndControlling" | "graphicsAndDesign" | "healthMedicineAndSocialAffairs" | "itAndSoftwareDevelopment" | "legalServices" | "marketingAndAdvertising" | "otherFieldsOfActivity" | "personnelAndHr" | "processPlanningAndQualityAssurance" | "productManagement";
export type TeamSize = "fiftyToHundred" | "oneToTen" | "overHundred" | "tenToTwentyFive" | "twentyFiveToFifty";
import { FragmentRefs } from "relay-runtime";
export type userEditScreen_UserFragment$data = {
  readonly activated: boolean;
  readonly email: string;
  readonly extension: {
    readonly adsOptIn?: boolean;
    readonly avatar?: {
      readonly id: string;
      readonly name: string;
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly branch?: Branch | null | undefined;
    readonly firstName?: string;
    readonly lastName?: string;
    readonly position?: string | null | undefined;
    readonly teamSize?: TeamSize | null | undefined;
  };
  readonly groupAssociations: ReadonlyArray<{
    readonly account: {
      readonly name: string;
    } | null | undefined;
    readonly group: {
      readonly id: string;
      readonly name: string;
    } | null | undefined;
  }>;
  readonly id: string;
  readonly isDeleted: boolean;
  readonly name: string;
  readonly registeredAt: string;
  readonly " $fragmentType": "userEditScreen_UserFragment";
};
export type userEditScreen_UserFragment$key = {
  readonly " $data"?: userEditScreen_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userEditScreen_UserFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userEditScreen_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDeleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserGroupAssociation",
      "kind": "LinkedField",
      "name": "groupAssociations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Account",
          "kind": "LinkedField",
          "name": "account",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserInAccountGroup",
          "kind": "LinkedField",
          "name": "group",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "extension",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "adsOptIn",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "branch",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "teamSize",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "position",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "avatar",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "UserExtensionImpl",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "7ab6a68bb1447de6a7def7f17ae9efb6";

export default node;
