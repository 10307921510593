import { Path } from "@thekeytechnology/epic-ui";
import { AcademiesTagsPath } from "@screens/academies-tags/academies-tags.paths";
import { AccountsPath } from "@screens/accounts/accounts.paths";
import { CoachAppointmentsPath } from "@screens/coach-appointments-edit/coach-appointments-edit.paths";
import { CoachProfilesPath } from "@screens/coach-profiles/coach-profiles.paths";
import { CoachingsPath } from "@screens/coaching-edit/coaching-edit.paths";
import { ContractPartnersPath } from "@screens/contract-partners/contract-partners.paths";
import { CustomerFeedbackPath } from "@screens/customer-feedback/customer-feedback.paths";
import { DiscountActionsPath } from "@screens/discount-actions/discount-actions.paths";
import { EducationalOfferPath } from "@screens/educational-offer/educational-offer.paths";
import { EmailTemplatesPath } from "@screens/email-templates/email-templates.paths";
import { ExternalLicensesPath } from "@screens/external-license-edit/external-license-edit.paths";
import { ExternalLicensePoolsPath } from "@screens/external-license-pools/external-license-pools.paths";
import { FilesPath } from "@screens/files/files.paths";
import { GenerateCartLinkPath } from "@screens/generate-cart-link/generate-cart-link.paths";
import { InstructorsPath } from "@screens/instructors/instructors.paths";
import { LicenseDefinitionsPath } from "@screens/license-definitions/license-definitions.paths";
import { LicensesPath } from "@screens/licenses/licenses.paths";
import { LimitedCartDiscountsPath } from "@screens/limited-cart-discounts/limited-cart-discounts.paths";
import { OffersPath } from "@screens/offers/offers.paths";
import { OrdersPath } from "@screens/orders/orders.paths";
import { PotentialAnalysisPaths } from "@screens/potential-analysis/potential-analysis.paths";
import { ProductsPath } from "@screens/products/products.paths";
import { ReportsPath } from "@screens/reports/reports.paths";
import { SettingsPath } from "@screens/settings/settings.paths";
import { StagingPath } from "@screens/staging/staging.paths";
import { TasksPath } from "@screens/tasks/tasks.paths";
import { TrendsPath } from "@screens/trends/trends.paths";
import { UsersPath } from "@screens/users/users.paths";
import { WordPackagesPath } from "@screens/word-packages/word-packages.paths";

/**
 * import files directly and not over the index file to avoid circular dependencies
 */

class PathIndex extends Path<typeof PathIndex> {
	static readonly childPaths = [
		OffersPath,
		EducationalOfferPath,
		FilesPath,
		WordPackagesPath,
		UsersPath,
		TasksPath,
		StagingPath,
		SettingsPath,
		LicensesPath,
		InstructorsPath,
		EmailTemplatesPath,
		CoachingsPath,
		CoachProfilesPath,
		CoachAppointmentsPath,
		AccountsPath,
		AcademiesTagsPath,
		OrdersPath,
		ProductsPath,
		LimitedCartDiscountsPath,
		GenerateCartLinkPath,
		DiscountActionsPath,
		LicenseDefinitionsPath,
		ContractPartnersPath,
		ExternalLicensePoolsPath,
		ExternalLicensesPath,
		ReportsPath,
		TrendsPath,
		CustomerFeedbackPath,
		PotentialAnalysisPaths,
	];
}

export const Paths = new PathIndex();
