/**
 * @generated SignedSource<<6eef4d0bf73d91522d1cf94cc69182ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductPriceColumn_ProductFragment$data = {
  readonly netPrice?: any;
  readonly " $fragmentType": "ProductPriceColumn_ProductFragment";
};
export type ProductPriceColumn_ProductFragment$key = {
  readonly " $data"?: ProductPriceColumn_ProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductPriceColumn_ProductFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "netPrice",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductPriceColumn_ProductFragment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "AccountGroupProduct",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "PlacementProduct",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "LicenseProduct",
      "abstractKey": null
    }
  ],
  "type": "Product",
  "abstractKey": "__isProduct"
};
})();

(node as any).hash = "6d3e36f366c52f5e3d90069c55fc6571";

export default node;
