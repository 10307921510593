/**
 * @generated SignedSource<<afa36dd4d01e27e1f1bc5ceaa6b6d309>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateLicenseProductInput = {
  clientMutationId?: string | null | undefined;
  title: string;
};
export type createLicenseProductButton_CreateLicenseProductMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateLicenseProductInput;
};
export type createLicenseProductButton_CreateLicenseProductMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly createLicenseProduct: {
        readonly edge: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"productsTable_ProductFragment">;
          };
        };
      } | null | undefined;
    };
  };
};
export type createLicenseProductButton_CreateLicenseProductMutation = {
  response: createLicenseProductButton_CreateLicenseProductMutation$data;
  variables: createLicenseProductButton_CreateLicenseProductMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "netPrice",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isTaxFree",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createLicenseProductButton_CreateLicenseProductMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateLicenseProductPayload",
                "kind": "LinkedField",
                "name": "createLicenseProduct",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductsEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "productsTable_ProductFragment",
                            "selections": [
                              (v3/*: any*/),
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ProductIdColumn_ProductFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ProductTitleColumn_ProductFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ProductIsHiddenColumn_ProductFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ProductPriceColumn_ProductFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "productTaxFreeColumn_ProductFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ProductActionColumn_ProductFragment"
                              }
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "createLicenseProductButton_CreateLicenseProductMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateLicenseProductPayload",
                "kind": "LinkedField",
                "name": "createLicenseProduct",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductsEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isProduct"
                          },
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isHidden",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v4/*: any*/),
                            "type": "AccountGroupProduct",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v4/*: any*/),
                            "type": "PlacementProduct",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v4/*: any*/),
                            "type": "LicenseProduct",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "edge",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0326a4d37cd15fb8ee514be6e3dfcff2",
    "id": null,
    "metadata": {},
    "name": "createLicenseProductButton_CreateLicenseProductMutation",
    "operationKind": "mutation",
    "text": "mutation createLicenseProductButton_CreateLicenseProductMutation(\n  $input: CreateLicenseProductInput!\n) {\n  Admin {\n    Billing {\n      createLicenseProduct(input: $input) {\n        edge {\n          node {\n            __typename\n            ...productsTable_ProductFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment ProductActionColumn_ProductFragment on Product {\n  __isProduct: __typename\n  id\n}\n\nfragment ProductIdColumn_ProductFragment on Product {\n  __isProduct: __typename\n  id\n}\n\nfragment ProductIsHiddenColumn_ProductFragment on Product {\n  __isProduct: __typename\n  isHidden\n}\n\nfragment ProductPriceColumn_ProductFragment on Product {\n  __isProduct: __typename\n  ... on AccountGroupProduct {\n    netPrice\n  }\n  ... on PlacementProduct {\n    netPrice\n  }\n  ... on LicenseProduct {\n    netPrice\n  }\n}\n\nfragment ProductTitleColumn_ProductFragment on Product {\n  __isProduct: __typename\n  title\n}\n\nfragment productTaxFreeColumn_ProductFragment on Product {\n  __isProduct: __typename\n  ... on AccountGroupProduct {\n    isTaxFree\n  }\n  ... on PlacementProduct {\n    isTaxFree\n  }\n  ... on LicenseProduct {\n    isTaxFree\n  }\n}\n\nfragment productsTable_ProductFragment on Product {\n  __isProduct: __typename\n  id\n  ...ProductIdColumn_ProductFragment\n  ...ProductTitleColumn_ProductFragment\n  ...ProductIsHiddenColumn_ProductFragment\n  ...ProductPriceColumn_ProductFragment\n  ...productTaxFreeColumn_ProductFragment\n  ...ProductActionColumn_ProductFragment\n}\n"
  }
};
})();

(node as any).hash = "828c4c9f842ea72e0515dcba45d6f6c6";

export default node;
