/**
 * @generated SignedSource<<8680ae53007b94f29e4f1dd2113aa6b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type limitedCartDiscountForm_ProductSelectionConditionFragment$data = {
  readonly amount: number;
  readonly id: string;
  readonly product: {
    readonly title?: string;
  } | null | undefined;
  readonly productId: string;
  readonly " $fragmentType": "limitedCartDiscountForm_ProductSelectionConditionFragment";
};
export type limitedCartDiscountForm_ProductSelectionConditionFragment$key = {
  readonly " $data"?: limitedCartDiscountForm_ProductSelectionConditionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"limitedCartDiscountForm_ProductSelectionConditionFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "limitedCartDiscountForm_ProductSelectionConditionFragment"
};

(node as any).hash = "d02ceb95e1e44c7e9ef4c71aacc9201d";

export default node;
