/**
 * @generated SignedSource<<54e0a5702877f0c001ac85b878a7bb2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ElementTypeV2 = "clozeText" | "dynamicVocab" | "enhancedText" | "file" | "globalVocab" | "markMistakes" | "matrix" | "multipleChoice" | "order" | "podcast" | "podcastWithTimestamp" | "text" | "video";
import { FragmentRefs } from "relay-runtime";
export type elementForm_ElementV2Fragment$data = {
  readonly elementType: ElementTypeV2;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"ClozeTextElementForm_ClozeTextElementFragment" | "MarkMistakeElementForm_MarkMistakeElementFragment" | "OrderElementForm_OrderElementFragment" | "PodcastWithTimestampElementForm_PodcastWithTimestampElementFragment" | "VideoElementForm_VideoElementFragment" | "dynamicVocabElementForm_DynamicVocabElementFragment" | "enhancedTextElementForm_EnhancedTextElementFragment" | "fileElementForm_FileElementV2Fragment" | "matrixElementForm_MatrixElementFragment" | "multipleChoiceElementForm_MultipleChoiceElementV2Fragment">;
  readonly " $fragmentType": "elementForm_ElementV2Fragment";
};
export type elementForm_ElementV2Fragment$key = {
  readonly " $data"?: elementForm_ElementV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"elementForm_ElementV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "elementForm_ElementV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementType",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "fileElementForm_FileElementV2Fragment"
        }
      ],
      "type": "FileElementV2",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "VideoElementForm_VideoElementFragment"
        }
      ],
      "type": "VideoElement",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "multipleChoiceElementForm_MultipleChoiceElementV2Fragment"
        }
      ],
      "type": "MultipleChoiceElementV2",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MarkMistakeElementForm_MarkMistakeElementFragment"
        }
      ],
      "type": "MarkMistakesElement",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PodcastWithTimestampElementForm_PodcastWithTimestampElementFragment"
        }
      ],
      "type": "PodcastWithTimestamp",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "matrixElementForm_MatrixElementFragment"
        }
      ],
      "type": "MatrixElement",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "enhancedTextElementForm_EnhancedTextElementFragment"
        }
      ],
      "type": "EnhancedTextElement",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OrderElementForm_OrderElementFragment"
        }
      ],
      "type": "OrderElement",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClozeTextElementForm_ClozeTextElementFragment"
        }
      ],
      "type": "ClozeTextElementV2",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "dynamicVocabElementForm_DynamicVocabElementFragment"
        }
      ],
      "type": "DynamicVocabElement",
      "abstractKey": null
    }
  ],
  "type": "ElementV2",
  "abstractKey": "__isElementV2"
};

(node as any).hash = "3f5caac469c58b1647d5b4ffbd591c69";

export default node;
