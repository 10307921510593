import graphql from "babel-plugin-relay/macro";

export const ACCOUNT_GROUP_PRODUCT_FRAGMENT = graphql`
	fragment accountGroupProductForm_AccountGroupProductFragment on AccountGroupProduct {
		id
		title
		isHidden
		isTaxFree
		netPrice
		accountGroupId
		costUnit
	}
`;

export const EDIT_ACCOUNT_GROUP_PRODUCT_MUTATION = graphql`
	mutation accountGroupProductForm_EditAccountGroupProductMutation(
		$input: EditAccountGroupProductInput!
	) {
		Admin {
			Billing {
				editAccountGroupProduct(input: $input) {
					product {
						...accountGroupProductForm_AccountGroupProductFragment
					}
				}
			}
		}
	}
`;
