/**
 * @generated SignedSource<<c27d300b5681f590c7b356c51d899b2a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type potentialAnalysisEdit_UserInlineFragment$data = {
  readonly extension: {
    readonly avatar?: {
      readonly thumbnail: string | null | undefined;
    } | null | undefined;
  };
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "potentialAnalysisEdit_UserInlineFragment";
};
export type potentialAnalysisEdit_UserInlineFragment$key = {
  readonly " $data"?: potentialAnalysisEdit_UserInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"potentialAnalysisEdit_UserInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "potentialAnalysisEdit_UserInlineFragment"
};

(node as any).hash = "f25536465636070be15dc57268fa7a4f";

export default node;
