import { FormDialogButton } from "@thekeytechnology/framework-react-components";
import { type FormikProps } from "formik";
import React, { useContext } from "react";
import { useMutation } from "react-relay";
import { toast } from "react-toastify";
import { CREATE_COACHING_OFFER_MUTATION } from "./create-coaching-offer-button.graphql";
import { type CreateCoachingOfferButtonProps } from "./create-coaching-offer-button.types";
import {
	type createCoachingOfferButton_CreateOfferMutation,
	type CreateCoachingOfferInput,
} from "../../../../__generated__/createCoachingOfferButton_CreateOfferMutation.graphql";
import { OffersScreenContext } from "../../offers.context";
import { CreateCoachingOfferForm } from "../create-coaching-offer-form";

export const CreateCoachingOfferButton = ({}: CreateCoachingOfferButtonProps) => {
	const [create, isCreating] = useMutation<createCoachingOfferButton_CreateOfferMutation>(
		CREATE_COACHING_OFFER_MUTATION,
	);
	const { connectionId } = useContext(OffersScreenContext);
	const buildHandleOnSubmit = (
		ref: React.MutableRefObject<FormikProps<CreateCoachingOfferInput> | null>,
		onHide: () => void,
		values: CreateCoachingOfferInput,
	) => {
		create({
			variables: {
				input: {
					kind: values.kind,
				},
				connections: [connectionId],
			},
			onCompleted: () => {
				ref.current?.setSubmitting(false);
				onHide();
				toast.success("Coachingangebot erstellt.");
			},
			onError: () => {
				ref.current?.setSubmitting(false);
				toast.error("Fehler beim erstellen des Coachingangebots");
			},
		});
	};

	return (
		<FormDialogButton<CreateCoachingOfferInput>
			disabled={isCreating}
			buttonContent={{
				label: "Coaching erstellen",
			}}
			title={"Coaching erstellen"}
		>
			{(ref, onHide) => {
				return (
					<CreateCoachingOfferForm
						initialState={{
							kind: "OneToOne",
						}}
						ref={ref}
						onSubmit={(values) => {
							buildHandleOnSubmit(ref, onHide, values);
						}}
					/>
				);
			}}
		</FormDialogButton>
	);
};
