import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query coachingEdit_Query($id: ID!) {
		Admin {
			Coaching {
				GetCoachingOffer(id: $id) {
					...coachingEdit_CoachingOfferFragment
				}
			}
		}
		...editAssociatedCoachesForm_QueryFragment
		...editCoachingOfferForm_QueryFragment
	}
`;
export const EDIT_OFFER_DESCRIPTION_MUTATION = graphql`
	mutation coachingEdit_EditOfferDescriptionMutation($input: EditOfferDescriptionInput!) {
		Admin {
			Coaching {
				editOfferDescription(input: $input) {
					coachingOffer {
						...coachingEdit_CoachingOfferFragment
					}
				}
			}
		}
	}
`;
export const EDIT_ASSOCIATED_COACHES_MUTATION = graphql`
	mutation coachingEdit_EditAssociatedCoachesMutation($input: EditAssociatedCoachesInput!) {
		Admin {
			Coaching {
				editAssociatedCoaches(input: $input) {
					coachingOffer {
						...coachingEdit_CoachingOfferFragment
					}
				}
			}
		}
	}
`;

export const EDIT_GROUP_SETTING_MUTATION = graphql`
	mutation coachingEdit_EditGroupSettingMutation($input: EditOneToOneSettingInput!) {
		Admin {
			Coaching {
				editGroupSetting(input: $input) {
					coachingOffer {
						...coachingEdit_CoachingOfferFragment
					}
				}
			}
		}
	}
`;

export const EDIT_COACHING_REMUNERATION_MUTATION = graphql`
	mutation coachingEdit_EditCoachingRemunerationMutation($input: EditRenumerationInput!) {
		Admin {
			Coaching {
				editCoachingRemuneration(input: $input) {
					coachingOffer {
						...coachingEdit_CoachingOfferFragment
					}
				}
			}
		}
	}
`;

export const EDIT_PARTICIPATION_CERTIFICATE_REWARD_MUTATION = graphql`
	mutation coachingEdit_EditCoachingOfferParticipationCertificateMutation(
		$input: EditCoachingOfferParticipationCertificateInput!
	) {
		Admin {
			Coaching {
				editCoachingOfferParticipationCertificate(input: $input) {
					coachingOffer {
						...coachingEdit_CoachingOfferFragment
						participationCertificate {
							name
							html
							variables
						}
					}
				}
			}
		}
	}
`;

export const COACHING_OFFER_FRAGMENT = graphql`
	fragment coachingEdit_CoachingOfferFragment on CoachingOffer {
		id
		description {
			description
			contents
			modules
			name
			image {
				id
				name
				thumbnail
			}
			contractPartner {
				id
				data {
					name
				}
			}
			shortDescription
			tags {
				id
				data {
					name
				}
			}
			licenseProduct {
				id
				title
			}
		}
		associatedCoaches {
			id
			coach {
				name
				id
			}
		}
		setting {
			kind
			... on GroupCoachingSetting {
				maxParticipants
			}
		}
		remunerationData {
			... on CoachingOfferRemunerationDataPerSession {
				sessionRate {
					grossPrice
					netPrice
					taxRatePercentage
				}
				additionalInformation
			}
			percentageShare
			kind
		}
		participationCertificate {
			name
			html
			variables
		}
		...visibilityCoachingOfferConfigEditor_CoachingOfferFragment
	}
`;
