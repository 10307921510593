import { DefaultTextAreaComponent } from "@thekeytechnology/framework-react-components";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import * as React from "react";
import { useFragment, useMutation } from "react-relay";
import * as Yup from "yup";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { MemoizedRichTextEditor } from "@components/rich-text-editor";
import { RichTextEditorToolbar } from "@components/rich-text-editor/rich-text-editor.const";
import { ValidatedField } from "@components/ValidatedField";
import { SelectProductFieldDialog } from "@features/products/select-product-field-dialog";
import { type Product } from "@features/products/select-product-field-dialog/select-product-field-dialog.interface";
import {
	SelectAcademiesTagField,
	type SelectAcademiesTagFieldState,
} from "@features/tags/select-academies-tag-field";
import { type editRootExtensionForm_QueryFragment$key } from "@relay/editRootExtensionForm_QueryFragment.graphql";
import { type editRootExtensionForm_RootExtensionImplFragment$key } from "@relay/editRootExtensionForm_RootExtensionImplFragment.graphql";
import { type editRootExtensionForm_SetRootExtensionImplMutation } from "@relay/editRootExtensionForm_SetRootExtensionImplMutation.graphql";
import {
	removeEditedFormFromEditedFormsArray,
	addEditedFormToEditedFormsArray,
} from "@store/slices/CoreSlice";
import { useTypedDispatch } from "@store/store.redux";
import {
	QUERY_FRAGMENT,
	ROOT_EXTENSION_IMPL_FRAGMENT,
	SET_ROOT_EXTENSION_IMPL_MUTATION,
} from "./edit-root-extension-form.graphql";
import {
	type EditRootExtensionFormProps,
	type EditRootExtensionFormState,
} from "./edit-root-extension-form.types";
import { type RootCostFormState } from "../root-cost-form";
import { RootCostInputField } from "../root-cost-input-field";

export const EditRootExtensionForm = ({
	rootId,
	rootExtensionImplFragmentRef,
	queryFragmentRef,
}: EditRootExtensionFormProps) => {
	const queryFragment = useFragment<editRootExtensionForm_QueryFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef,
	);
	const extension = useFragment<editRootExtensionForm_RootExtensionImplFragment$key>(
		ROOT_EXTENSION_IMPL_FRAGMENT,
		rootExtensionImplFragmentRef,
	);

	const dispatch = useTypedDispatch();
	const [setRootExtensionImpl, isSettingRootExtensionImpl] =
		useMutation<editRootExtensionForm_SetRootExtensionImplMutation>(
			SET_ROOT_EXTENSION_IMPL_MUTATION,
		);

	const formId = "EditNodeExtensionForm";
	const formik = useFormik<EditRootExtensionFormState>({
		enableReinitialize: true,
		initialValues: {
			learnGoals: extension.learnGoalsOpt ?? undefined,
			trailerVimeoIdOpt: extension.trailerOpt,
			costOpt: {
				price: extension.cost?.price ?? 0,
				percentageShare: extension.cost?.percentageShare ?? 0,
				supplier: extension.cost?.supplier ?? "",
			},
			tags: extension.tags.map((t) => ({
				id: t.id,
				data: {
					name: t.data.name,
				},
			})) as SelectAcademiesTagFieldState,
			licenseProduct: extension.licenseProduct ?? undefined,
			diploma: extension.diploma,
		},
		validationSchema: Yup.object().shape({
			prefixTitle: Yup.string(),
			price: Yup.number().optional(),
			supplier: Yup.string().optional(),
			costOpt: Yup.object().optional(),
		}),
		onSubmit: (values, { setSubmitting }) => {
			setRootExtensionImpl({
				variables: {
					input: {
						rootId,
						learnGoals: values.learnGoals,
						trailerVimeoId: values.trailerVimeoIdOpt,
						cost: values.costOpt,
						tagIds: values.tags.map((t) => t.id),
						licenseProductId: values.licenseProduct?.id,
						diploma: values.diploma,
					},
				},
				onCompleted: () => {
					void formik.setTouched({});
					setSubmitting(false);
					dispatch(removeEditedFormFromEditedFormsArray({ form: formId }));
				},
			});
		},
	});

	const handleFormEdited = React.useCallback(
		() => dispatch(addEditedFormToEditedFormsArray({ form: formId })),
		[dispatch, formId],
	);

	return (
		<Card className="mb-2">
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField
					name={"diploma"}
					label={"Diplom"}
					component={DefaultTextAreaComponent}
					onChange={handleFormEdited}
					formikConfig={formik}
				/>
				<ValidatedField<EditRootExtensionFormState, string>
					name={"learnGoals"}
					label={"Lernziele"}
					component={(props) => (
						<MemoizedRichTextEditor
							{...props}
							height={200}
							toolbar={RichTextEditorToolbar.WithLists}
						/>
					)}
					onChange={handleFormEdited}
					formikConfig={formik}
				/>
				<ValidatedField<EditRootExtensionFormState, string>
					name={"trailerVimeoIdOpt"}
					label={"Vimeo-ID"}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<ValidatedField<EditRootExtensionFormState, RootCostFormState>
					name={"costOpt"}
					label={"Kosten"}
					component={RootCostInputField}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<ValidatedField<EditRootExtensionFormState, SelectAcademiesTagFieldState>
					name={"tags"}
					label={"Schlagworte"}
					component={(renderConfig) => (
						<SelectAcademiesTagField
							queryFragmentRef={queryFragment}
							{...renderConfig}
						/>
					)}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<ValidatedField<EditRootExtensionFormState, Product>
					name={"licenseProduct"}
					label={"Produkt"}
					component={SelectProductFieldDialog}
					formikConfig={formik}
				/>

				<Button
					disabled={
						Object.entries(formik.touched).length === 0 || isSettingRootExtensionImpl
					}
					type="submit"
					label="Speichern"
					className="p-mt-2"
				/>
			</form>
		</Card>
	);
};
