import { useContext } from "react";
import { useMutation } from "react-relay";
import { toast } from "react-toastify";
import { Button } from "@components/button";
import { type createCourseLearnableButton_ConstellationCreateBranchRootMutation } from "@relay/createCourseLearnableButton_ConstellationCreateBranchRootMutation.graphql";
import { CREATE_MUTATION } from "./create-course-learnable-button.graphql";
import { type CreateCourseLearnableButtonProps } from "./create-course-learnable-button.types";
import { OffersScreenContext } from "../../offers.context";

export const CreateCourseLearnableButton = ({ ...props }: CreateCourseLearnableButtonProps) => {
	const [create] =
		useMutation<createCourseLearnableButton_ConstellationCreateBranchRootMutation>(
			CREATE_MUTATION,
		);
	const { connectionId } = useContext(OffersScreenContext);

	const handleOnClick = () => {
		create({
			variables: {
				input: {
					title: "UnpublishedCourseLearnable",
				},
				connections: [connectionId],
			},
			onCompleted: () => {
				toast.success("Weiterbildung erstellt");
			},
			onError: () => {
				toast.error("Fehler beim erstellen einer Weiterbildung");
			},
		});
	};

	return <Button onClick={handleOnClick} label="Weiterbildung erstellen" {...props} />;
};
