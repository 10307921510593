/**
 * @generated SignedSource<<91343a7c5f52c60a93dcca21bb8e0c96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditRenumerationInput = {
  additionalInformation?: string | null | undefined;
  clientMutationId?: string | null | undefined;
  id: string;
  percentageShare: number;
  sessionRate: PriceInput;
};
export type PriceInput = {
  grossPrice?: any | null | undefined;
  netPrice?: any | null | undefined;
  taxRatePercentage?: any | null | undefined;
};
export type coachingEdit_EditCoachingRemunerationMutation$variables = {
  input: EditRenumerationInput;
};
export type coachingEdit_EditCoachingRemunerationMutation$data = {
  readonly Admin: {
    readonly Coaching: {
      readonly editCoachingRemuneration: {
        readonly coachingOffer: {
          readonly " $fragmentSpreads": FragmentRefs<"coachingEdit_CoachingOfferFragment">;
        };
      } | null | undefined;
    };
  };
};
export type coachingEdit_EditCoachingRemunerationMutation = {
  response: coachingEdit_EditCoachingRemunerationMutation$data;
  variables: coachingEdit_EditCoachingRemunerationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "coachingEdit_EditCoachingRemunerationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoachingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Coaching",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditRenumerationPayload",
                "kind": "LinkedField",
                "name": "editCoachingRemuneration",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoachingOffer",
                    "kind": "LinkedField",
                    "name": "coachingOffer",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "coachingEdit_CoachingOfferFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "coachingEdit_EditCoachingRemunerationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoachingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Coaching",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditRenumerationPayload",
                "kind": "LinkedField",
                "name": "editCoachingRemuneration",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoachingOffer",
                    "kind": "LinkedField",
                    "name": "coachingOffer",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CoachingOfferDescription",
                        "kind": "LinkedField",
                        "name": "description",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "contents",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "modules",
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "image",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "thumbnail",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContractPartner",
                            "kind": "LinkedField",
                            "name": "contractPartner",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ContractPartnerData",
                                "kind": "LinkedField",
                                "name": "data",
                                "plural": false,
                                "selections": (v4/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "shortDescription",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "tags",
                            "plural": true,
                            "selections": [
                              (v5/*: any*/),
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AcademiesTagData",
                                "kind": "LinkedField",
                                "name": "data",
                                "plural": false,
                                "selections": (v4/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LicenseProduct",
                            "kind": "LinkedField",
                            "name": "licenseProduct",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CoachProfile",
                        "kind": "LinkedField",
                        "name": "associatedCoaches",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "coach",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "setting",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "maxParticipants",
                                "storageKey": null
                              }
                            ],
                            "type": "GroupCoachingSetting",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "remunerationData",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Price",
                                "kind": "LinkedField",
                                "name": "sessionRate",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "grossPrice",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "netPrice",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "taxRatePercentage",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "additionalInformation",
                                "storageKey": null
                              }
                            ],
                            "type": "CoachingOfferRemunerationDataPerSession",
                            "abstractKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "percentageShare",
                            "storageKey": null
                          },
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ParticipationCertificate",
                        "kind": "LinkedField",
                        "name": "participationCertificate",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "html",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "variables",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "visibilityConfigs",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "configType",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d65a318f897d875065bf0dfff377e041",
    "id": null,
    "metadata": {},
    "name": "coachingEdit_EditCoachingRemunerationMutation",
    "operationKind": "mutation",
    "text": "mutation coachingEdit_EditCoachingRemunerationMutation(\n  $input: EditRenumerationInput!\n) {\n  Admin {\n    Coaching {\n      editCoachingRemuneration(input: $input) {\n        coachingOffer {\n          ...coachingEdit_CoachingOfferFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment coachingEdit_CoachingOfferFragment on CoachingOffer {\n  id\n  description {\n    description\n    contents\n    modules\n    name\n    image {\n      id\n      name\n      thumbnail\n    }\n    contractPartner {\n      id\n      data {\n        name\n      }\n    }\n    shortDescription\n    tags {\n      __typename\n      id\n      data {\n        name\n      }\n    }\n    licenseProduct {\n      id\n      title\n    }\n  }\n  associatedCoaches {\n    id\n    coach {\n      name\n      id\n    }\n  }\n  setting {\n    __typename\n    kind\n    ... on GroupCoachingSetting {\n      maxParticipants\n    }\n  }\n  remunerationData {\n    __typename\n    ... on CoachingOfferRemunerationDataPerSession {\n      sessionRate {\n        grossPrice\n        netPrice\n        taxRatePercentage\n      }\n      additionalInformation\n    }\n    percentageShare\n    kind\n  }\n  participationCertificate {\n    name\n    html\n    variables\n  }\n  ...visibilityCoachingOfferConfigEditor_CoachingOfferFragment\n}\n\nfragment visibilityCoachingOfferConfigEditor_CoachingOfferFragment on CoachingOffer {\n  id\n  visibilityConfigs {\n    __typename\n    id\n    configType\n  }\n}\n"
  }
};
})();

(node as any).hash = "5cec1471edbfb11495caa55b788f482c";

export default node;
