import { useFormik } from "formik";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { Suspense, useRef } from "react";
import { useFragment, useMutation } from "react-relay";
import * as Yup from "yup";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { accountGroupProductForm_AccountGroupProductFragment$key } from "@relay/accountGroupProductForm_AccountGroupProductFragment.graphql";
import { accountGroupProductForm_EditAccountGroupProductMutation } from "@relay/accountGroupProductForm_EditAccountGroupProductMutation.graphql";
import {
	ACCOUNT_GROUP_PRODUCT_FRAGMENT,
	EDIT_ACCOUNT_GROUP_PRODUCT_MUTATION,
} from "@screens/product-edit/parts/account-group-product-form/account-group-product-form.graphql";
import {
	AccountGroupProductFormProps,
	AccountGroupProductFormState,
} from "@screens/product-edit/parts/account-group-product-form/account-group-product-form.types";
import { LicenseProductFormState } from "@screens/product-edit/parts/license-product-form/license-product-form.types";
import { ProductForm } from "@screens/product-edit/parts/product-form/product-form.component";

export const AccountGroupProductForm = ({ productFragmentRef }: AccountGroupProductFormProps) => {
	const toast = useRef<Toast>(null);

	const product = useFragment<accountGroupProductForm_AccountGroupProductFragment$key>(
		ACCOUNT_GROUP_PRODUCT_FRAGMENT,
		productFragmentRef,
	);

	const [editProduct] = useMutation<accountGroupProductForm_EditAccountGroupProductMutation>(
		EDIT_ACCOUNT_GROUP_PRODUCT_MUTATION,
	);

	const formik = useFormik<AccountGroupProductFormState>({
		enableReinitialize: true,
		initialValues: {
			title: product.title,
			isTaxFree: product.isTaxFree,
			isHidden: product.isHidden,
			netPrice: product.netPrice,
			costUnit: product.costUnit ?? "",
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Ein Titel wird benötigt."),
			netPrice: Yup.number().required(),
			isHidden: Yup.boolean(),
			costUnit: Yup.string().optional(),
		}),
		onSubmit: (values: AccountGroupProductFormState, { setSubmitting, resetForm }) => {
			editProduct({
				variables: {
					input: {
						id: product.id,
						netPrice: values.netPrice,
						title: values.title,
						isHidden: values.isHidden ?? true,
						isTaxFree: values.isTaxFree,
						costUnit: values.costUnit,
						accountGroupId: product.accountGroupId!,
					},
				},
			});
			setSubmitting(false);
		},
	});

	return (
		<>
			<Toast ref={toast} />
			<Suspense fallback={<ProgressSpinner />}>
				<ProductForm formik={formik} handleSubmit={formik.handleSubmit}>
					<ValidatedField<LicenseProductFormState, string>
						name={"costUnit"}
						label="Kostenstelle"
						component={DefaultTextFieldComponent}
						formikConfig={formik}
					/>
				</ProductForm>
			</Suspense>
		</>
	);
};
