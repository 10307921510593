/**
 * @generated SignedSource<<a03be05c1a23afc9401f3536f7b4ab7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetRootExtensionImplInput = {
  clientMutationId?: string | null | undefined;
  cost?: RootCostInput | null | undefined;
  diploma?: string | null | undefined;
  learnGoals?: string | null | undefined;
  licenseProductId?: string | null | undefined;
  rootId: string;
  tagIds: ReadonlyArray<string>;
  trailerVimeoId?: string | null | undefined;
};
export type RootCostInput = {
  percentageShare: number;
  price: any;
  supplier: string;
};
export type editRootExtensionForm_SetRootExtensionImplMutation$variables = {
  input: SetRootExtensionImplInput;
};
export type editRootExtensionForm_SetRootExtensionImplMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly setRootExtensionImpl: {
        readonly root: {
          readonly structureDefinition: {
            readonly extension?: {
              readonly " $fragmentSpreads": FragmentRefs<"editRootExtensionForm_RootExtensionImplFragment">;
            };
          };
        };
      } | null | undefined;
    };
  };
};
export type editRootExtensionForm_SetRootExtensionImplMutation = {
  response: editRootExtensionForm_SetRootExtensionImplMutation$data;
  variables: editRootExtensionForm_SetRootExtensionImplMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editRootExtensionForm_SetRootExtensionImplMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetRootExtensionImplPayload",
                "kind": "LinkedField",
                "name": "setRootExtensionImpl",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "root",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "extension",
                                "plural": false,
                                "selections": [
                                  {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "editRootExtensionForm_RootExtensionImplFragment"
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RootStructureDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editRootExtensionForm_SetRootExtensionImplMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetRootExtensionImplPayload",
                "kind": "LinkedField",
                "name": "setRootExtensionImpl",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "root",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "extension",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "learnGoalsOpt",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "trailerOpt",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "RootCost",
                                        "kind": "LinkedField",
                                        "name": "cost",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "price",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "supplier",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "percentageShare",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "tags",
                                        "plural": true,
                                        "selections": [
                                          (v2/*: any*/),
                                          (v3/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "AcademiesTagData",
                                            "kind": "LinkedField",
                                            "name": "data",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "name",
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "LicenseProduct",
                                        "kind": "LinkedField",
                                        "name": "licenseProduct",
                                        "plural": false,
                                        "selections": [
                                          (v3/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "title",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "diploma",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "RootExtensionImpl",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RootStructureDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "84cb567a7151c6ea62ecfb87d21545c1",
    "id": null,
    "metadata": {},
    "name": "editRootExtensionForm_SetRootExtensionImplMutation",
    "operationKind": "mutation",
    "text": "mutation editRootExtensionForm_SetRootExtensionImplMutation(\n  $input: SetRootExtensionImplInput!\n) {\n  Admin {\n    Tree {\n      setRootExtensionImpl(input: $input) {\n        root {\n          structureDefinition {\n            __typename\n            ... on RootStructureDefinition {\n              extension {\n                __typename\n                ...editRootExtensionForm_RootExtensionImplFragment\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment editRootExtensionForm_RootExtensionImplFragment on RootExtensionImpl {\n  learnGoalsOpt\n  trailerOpt\n  cost {\n    price\n    supplier\n    percentageShare\n  }\n  tags {\n    __typename\n    id\n    data {\n      name\n    }\n  }\n  licenseProduct {\n    id\n    title\n  }\n  diploma\n}\n"
  }
};
})();

(node as any).hash = "ad4c7196559095e5501e15c72012819d";

export default node;
