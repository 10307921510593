import { graphql } from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { useMutation } from "react-relay";
import { type CreateVideoElementButton_CreateVideoElementMutation } from "../../../__generated__/CreateVideoElementButton_CreateVideoElementMutation.graphql";

const CREATE_VIDEO_ELEMENT_MUTATION = graphql`
	mutation CreateVideoElementButton_CreateVideoElementMutation(
		$input: CreateVideoElementV2Input!
	) {
		Admin {
			ElearningV2 {
				createVideoElement(input: $input) {
					eLearningContentNode {
						...contentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;

type OwnProps = {
	eLearningContentNodeId: string;
};

export const CreateVideoElementButton = ({ eLearningContentNodeId }: OwnProps) => {
	const [createVideoElement, isCreatingVideoElement] =
		useMutation<CreateVideoElementButton_CreateVideoElementMutation>(
			CREATE_VIDEO_ELEMENT_MUTATION,
		);

	return (
		<Button
			className={"mr-2"}
			disabled={isCreatingVideoElement}
			tooltip={"Video-Baustein anlegen"}
			icon="pi pi-video"
			onClick={() => {
				createVideoElement({
					variables: {
						input: {
							title: "Neues Element",
							vimeoId: "",
							eLearningContentNodeId,
						},
					},
				});
			}}
		/>
	);
};
