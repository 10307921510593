import graphql from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import React from "react";
import { useMutation } from "react-relay";
import { type CreateEnhancedTextElementButton_CreateEnhancedTextElementMutation } from "@relay/CreateEnhancedTextElementButton_CreateEnhancedTextElementMutation.graphql";

const CREATE_ENHANCED_TEXT_ELEMENT_MUTATION = graphql`
	mutation CreateEnhancedTextElementButton_CreateEnhancedTextElementMutation(
		$input: CreateEnhancedTextElementInput!
	) {
		Admin {
			ElearningV2 {
				createEnhancedTextElement(input: $input) {
					eLearningContentNode {
						...contentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;

type OwnProps = {
	eLearningContentNodeId: string;
};

export const CreateEnhancedTextElementButton = ({ eLearningContentNodeId }: OwnProps) => {
	const [CreateEnhancedTextElement, isCreatingMarkMistakeElement] =
		useMutation<CreateEnhancedTextElementButton_CreateEnhancedTextElementMutation>(
			CREATE_ENHANCED_TEXT_ELEMENT_MUTATION,
		);
	return (
		<Button
			className={"mr-2"}
			disabled={isCreatingMarkMistakeElement}
			tooltip={`Erweiterter Text-Baustein anlegen`}
			icon="pi pi-times-circle"
			onClick={() => {
				CreateEnhancedTextElement({
					variables: {
						input: {
							title: "Neues Element",
							text: "",
							eLearningContentNodeId,
						},
					},
				});
			}}
		/>
	);
};
