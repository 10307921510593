/**
 * @generated SignedSource<<22ca267e076ce4fd223858a8bcedfc34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CoachingOfferRemunerationDataKind = "NotRemunerated" | "PerSession";
export type CoachingSettingKind = "Group" | "OneToOne";
import { FragmentRefs } from "relay-runtime";
export type coachingEdit_CoachingOfferFragment$data = {
  readonly associatedCoaches: ReadonlyArray<{
    readonly coach: {
      readonly id: string;
      readonly name: string;
    } | null | undefined;
    readonly id: string;
  }>;
  readonly description: {
    readonly contents: string | null | undefined;
    readonly contractPartner: {
      readonly data: {
        readonly name: string;
      };
      readonly id: string;
    } | null | undefined;
    readonly description: string | null | undefined;
    readonly image: {
      readonly id: string;
      readonly name: string;
      readonly thumbnail: string | null | undefined;
    } | null | undefined;
    readonly licenseProduct: {
      readonly id: string;
      readonly title: string;
    } | null | undefined;
    readonly modules: string | null | undefined;
    readonly name: string;
    readonly shortDescription: string | null | undefined;
    readonly tags: ReadonlyArray<{
      readonly data: {
        readonly name: string;
      };
      readonly id: string;
    }>;
  } | null | undefined;
  readonly id: string;
  readonly participationCertificate: {
    readonly html: string;
    readonly name: string;
    readonly variables: ReadonlyArray<string>;
  } | null | undefined;
  readonly remunerationData: {
    readonly additionalInformation?: string | null | undefined;
    readonly kind: CoachingOfferRemunerationDataKind;
    readonly percentageShare: number;
    readonly sessionRate?: {
      readonly grossPrice: any;
      readonly netPrice: any;
      readonly taxRatePercentage: any;
    };
  };
  readonly setting: {
    readonly kind: CoachingSettingKind;
    readonly maxParticipants?: number;
  };
  readonly " $fragmentSpreads": FragmentRefs<"visibilityCoachingOfferConfigEditor_CoachingOfferFragment">;
  readonly " $fragmentType": "coachingEdit_CoachingOfferFragment";
};
export type coachingEdit_CoachingOfferFragment$key = {
  readonly " $data"?: coachingEdit_CoachingOfferFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"coachingEdit_CoachingOfferFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "coachingEdit_CoachingOfferFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CoachingOfferDescription",
      "kind": "LinkedField",
      "name": "description",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contents",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "modules",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thumbnail",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ContractPartner",
          "kind": "LinkedField",
          "name": "contractPartner",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ContractPartnerData",
              "kind": "LinkedField",
              "name": "data",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shortDescription",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "tags",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "AcademiesTagData",
              "kind": "LinkedField",
              "name": "data",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LicenseProduct",
          "kind": "LinkedField",
          "name": "licenseProduct",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CoachProfile",
      "kind": "LinkedField",
      "name": "associatedCoaches",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "coach",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "setting",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "maxParticipants",
              "storageKey": null
            }
          ],
          "type": "GroupCoachingSetting",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "remunerationData",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Price",
              "kind": "LinkedField",
              "name": "sessionRate",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "grossPrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "netPrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "taxRatePercentage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "additionalInformation",
              "storageKey": null
            }
          ],
          "type": "CoachingOfferRemunerationDataPerSession",
          "abstractKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "percentageShare",
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ParticipationCertificate",
      "kind": "LinkedField",
      "name": "participationCertificate",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "html",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "variables",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "visibilityCoachingOfferConfigEditor_CoachingOfferFragment"
    }
  ],
  "type": "CoachingOffer",
  "abstractKey": null
};
})();

(node as any).hash = "d6d5d436e89f9735f34d772911003285";

export default node;
