/**
 * @generated SignedSource<<bfbe38aa2e5bfffaf75f2de9291da49b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type invoiceProviderLink_InvoiceDataFragment$data = {
  readonly invoiceId: string;
  readonly invoiceNumber: string | null | undefined;
  readonly " $fragmentType": "invoiceProviderLink_InvoiceDataFragment";
};
export type invoiceProviderLink_InvoiceDataFragment$key = {
  readonly " $data"?: invoiceProviderLink_InvoiceDataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"invoiceProviderLink_InvoiceDataFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "invoiceProviderLink_InvoiceDataFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceNumber",
      "storageKey": null
    }
  ],
  "type": "InvoiceData",
  "abstractKey": null
};

(node as any).hash = "33c91cf45cbd9836db9fe64284937810";

export default node;
