/**
 * @generated SignedSource<<25c3bf7e8310e28f2da702cac25295d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type cartForm_OrderFragment$data = {
  readonly cart: {
    readonly selection: {
      readonly selectedDiscountCodes: ReadonlyArray<string>;
      readonly selectedProducts: ReadonlyArray<{
        readonly amount: number;
        readonly product: {
          readonly title: string;
        } | null | undefined;
      }>;
    };
  } | null | undefined;
  readonly " $fragmentType": "cartForm_OrderFragment";
};
export type cartForm_OrderFragment$key = {
  readonly " $data"?: cartForm_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"cartForm_OrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "cartForm_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Selection",
          "kind": "LinkedField",
          "name": "selection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SelectedProduct",
              "kind": "LinkedField",
              "name": "selectedProducts",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "selectedDiscountCodes",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "373e39f94e57b4f56e03d9e0a42f3bc5";

export default node;
