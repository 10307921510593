import moment from "moment";
import { Button } from "primereact/button";
import React, { forwardRef, useCallback, useEffect, useMemo } from "react";
import { readInlineData, usePaginationFragment } from "react-relay";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type licensesTable_AccountFragment$key } from "@relay/licensesTable_AccountFragment.graphql";
import {
	type licensesTable_LicenseFragment$data,
	type licensesTable_LicenseFragment$key,
} from "@relay/licensesTable_LicenseFragment.graphql";
import { type licensesTable_LicensesFragment$key } from "@relay/licensesTable_LicensesFragment.graphql";
import { type licensesTable_Refetch } from "@relay/licensesTable_Refetch.graphql";
import { ACCOUNT_FRAGMENT, LICENSE_FRAGMENT, QUERY_FRAGMENT } from "./licenses-table.graphql";
import { IdDisplayField, IdWrapper } from "./licenses-table.styles";
import { type LicensesTableProps } from "./licenses-table.types";
import { DataTable } from "../../../components/data-table";
import { NoAccess } from "../../../components/no-access";
import { CreateLicensesButton } from "../create-licenses-button";
import { DeleteLicenseButton } from "../delete-license-button";

const LicensesTable = forwardRef(
	({ licensesFragmentRef, accountName, showDeleteButton, accountId }: LicensesTableProps) => {
		const {
			data: Admin,
			hasPrevious,
			hasNext,
			loadPrevious,
			refetch,
			loadNext,
		} = usePaginationFragment<licensesTable_Refetch, licensesTable_LicensesFragment$key>(
			QUERY_FRAGMENT,
			licensesFragmentRef,
		);

		useEffect(() => {
			refetch({
				nameOpt: accountName,
			});
		}, [accountName]);

		const accountConnection = Admin.Admin.Auth.SelectAccounts.edges?.map((e) =>
			readInlineData<licensesTable_AccountFragment$key>(ACCOUNT_FRAGMENT, e!.node),
		);
		const selectedAccount = useMemo(
			() => accountConnection?.find((acc) => acc.name === accountName),
			[accountName, accountConnection],
		);
		const licensesByAccount = useMemo(
			() =>
				selectedAccount?.extension.licenses!.edges?.map((e) =>
					readInlineData<licensesTable_LicenseFragment$key>(LICENSE_FRAGMENT, e!.node),
				),
			[selectedAccount],
		);

		const licenseConnectionId = selectedAccount?.extension.licenses!.__id;
		const buildCreationInfoColumnBody = (license: licensesTable_LicenseFragment$data) => {
			switch (license.creationInfo.kind) {
				case "Order":
					return (
						<IdWrapper>
							Bestellung:
							<IdDisplayField id={license.creationInfo.orderId} />
						</IdWrapper>
					);
				case "Admin":
					return (
						<IdWrapper>
							Admin:
							<IdDisplayField id={license.creationInfo.creatorId} />
						</IdWrapper>
					);
				case "PotentialAnalysis":
					return (
						<IdWrapper>
							PA:
							<IdDisplayField id={license.creationInfo.fromUserId} />
						</IdWrapper>
					);
				case "Registration":
					return (
						<IdWrapper>
							Registrierung:
							<IdDisplayField id={license.creationInfo.userId} />
						</IdWrapper>
					);
				case "Placement":
					return <div>Placement Matching</div>;
				default:
					return <div>N/A</div>;
			}
		};
		const buildUsedAtColumnBody = (license: licensesTable_LicenseFragment$data) => {
			return license.usage.kind === "UsedForTree" ||
				license.usage.kind === "UsedForCoaching" ||
				license.usage.kind === "UsedForAVGSPotentialAnalysis"
				? moment(license.usage.at?.replace("[UTC]", "")).format("DD.MM.YYYY - HH:mm")
				: "Nicht benutzt";
		};

		const buildCreatedAtColumnBody = (license: licensesTable_LicenseFragment$data) => {
			return moment(license.creationInfo.createdAt.replace("[UTC]", "")).format(
				"DD.MM.YYYY - HH:mm",
			);
		};

		const buildLicenseDefinitionColumnBody = (license: licensesTable_LicenseFragment$data) => {
			return license.creationInfo?.licenseDefinition?.data.name;
		};

		const buildUsedByColumnBody = (license: licensesTable_LicenseFragment$data) =>
			license.usage.kind === "UsedForTree" ||
			license.usage.kind === "UsedForCoaching" ||
			license.usage.kind === "UsedForAVGSPotentialAnalysis"
				? license.usage.forUser?.email
				: "Nicht benutzt";

		const buildActionColumnBody = (license: licensesTable_LicenseFragment$data) => {
			return licenseConnectionId && showDeleteButton && license.usage.kind === "Free" ? (
				<DeleteLicenseButton
					selectedIds={[license.id]}
					connectionId={licenseConnectionId}
				/>
			) : undefined;
		};

		const handlePreviousOnClick = useCallback(() => loadPrevious(20), [loadPrevious]);
		const handleNextOnClick = useCallback(() => loadNext(20), [loadNext]);

		const noAccountSpecified = <div>Keine Konto ausgewählt</div>;

		const canRead = useHasPermissions(["UserInAccountPermission_LicenseAdmin_Read"]);

		const table = useMemo(() => {
			if (!licensesByAccount) return noAccountSpecified;
			return (
				<React.Fragment>
					<DataTable
						responsiveLayout="stack"
						emptyMessage="Keine Lizenzen"
						className="mb-3"
						value={licensesByAccount}
						header={
							<div className="flex flex-row justify-content-between align-items-center">
								<div className="flex flex-row">
									Lizenzen für {selectedAccount?.name}
								</div>
								<CreateLicensesButton
									accountId={accountId}
									queryFragmentRef={Admin}
									connectionId={licenseConnectionId}
								/>
							</div>
						}
					>
						{(Column) => (
							<React.Fragment>
								<Column
									header="ID"
									body={(license) => <IdDisplayField id={license.id} />}
								/>
								<Column
									header={"Erstellt durch"}
									body={buildCreationInfoColumnBody}
								/>
								<Column
									header={"Lizenzdefinition"}
									body={buildLicenseDefinitionColumnBody}
								/>
								<Column header={"Angelegt am"} body={buildCreatedAtColumnBody} />
								<Column header={"Benutzt am"} body={buildUsedAtColumnBody} />
								<Column header={"Benutzt von"} body={buildUsedByColumnBody} />
								<Column header={"Aktionen"} body={buildActionColumnBody} />
							</React.Fragment>
						)}
					</DataTable>
					<div className="flex justify-content-center align-items-center">
						<Button
							disabled={!hasPrevious}
							onClick={handlePreviousOnClick}
							className="mr-3 p-button-secondary"
						>
							Zurück
						</Button>
						<Button
							className="p-button-secondary"
							disabled={!hasNext}
							onClick={handleNextOnClick}
						>
							Weiter
						</Button>
					</div>
				</React.Fragment>
			);
		}, [licensesByAccount]);

		if (!canRead) return <NoAccess />;

		return <>{table}</>;
	},
);

LicensesTable.displayName = "LicensesTable";

export { LicensesTable };
