import { MiddlewareNextFn, RelayRequestAny } from "react-relay-network-modern";
import { toast } from "react-toastify";
import { logout } from "../store/slices/AuthSlice";
import { ReduxStore } from "../store/store.redux";
import { ERROR_MESSAGES } from "../translations/error-messages";

const LOGOUT_ERRORS = ["auth_refresh_token_expired", "auth_jwt_malformed"].map((e) =>
	e.toLowerCase(),
);
const AVGS_POTENTIAL_ANALYSIS_USER_ALREADY_EXIST_ERRORS = [
	"avgspotentialanalysis_client_already_registered",
];

export const ErrorHandlingMiddleware = (next: MiddlewareNextFn) => async (req: RelayRequestAny) => {
	const res = await next(req);
	const errors = res.errors?.map((e) => e.message?.toLowerCase()) || [];
	const needsToLogout = LOGOUT_ERRORS.find((le) => errors.includes(le));
	const avgsPotentialAnalysisUserAlreadyExistError =
		AVGS_POTENTIAL_ANALYSIS_USER_ALREADY_EXIST_ERRORS.find((le) => errors.includes(le));
	if (needsToLogout) {
		ReduxStore.dispatch(logout());
	} else if (avgsPotentialAnalysisUserAlreadyExistError) {
		toast.error(
			"Der Benutzer existiert bereits und kann nicht zu einer Potential-Analyse hinzugefügt werden.",
		);
	} else {
		errors?.forEach((e) => {
			const message = ERROR_MESSAGES[e] || e;
			toast.error(message);
		});
	}

	return res;
};
