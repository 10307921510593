import { useFormik } from "formik";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { Suspense, useRef } from "react";
import { useFragment, useMutation } from "react-relay";
import * as Yup from "yup";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { placementProductForm_EditPlacementProductMutation } from "@relay/placementProductForm_EditPlacementProductMutation.graphql";
import { placementProductForm_PlacementProductFragment$key } from "@relay/placementProductForm_PlacementProductFragment.graphql";
import { AccountGroupProductFormState } from "@screens/product-edit/parts/account-group-product-form/account-group-product-form.types";
import {
	EDIT_PLACEMENT_PRODUCT_MUTATION,
	PLACEMENT_PRODUCT_FRAGMENT,
} from "@screens/product-edit/parts/placement-product-form/placement-product-form.graphql";
import {
	PlacementProductFormProps,
	PlacementProductFormState,
} from "@screens/product-edit/parts/placement-product-form/placement-product-form.types";
import { ProductForm } from "@screens/product-edit/parts/product-form/product-form.component";

export const PlacementProductForm = ({ productFragmentRef }: PlacementProductFormProps) => {
	const toast = useRef<Toast>(null);

	const product = useFragment<placementProductForm_PlacementProductFragment$key>(
		PLACEMENT_PRODUCT_FRAGMENT,
		productFragmentRef,
	);

	const [editProduct] = useMutation<placementProductForm_EditPlacementProductMutation>(
		EDIT_PLACEMENT_PRODUCT_MUTATION,
	);

	const formik = useFormik<PlacementProductFormState>({
		enableReinitialize: true,
		initialValues: {
			title: product.title,
			isHidden: product.isHidden,
			isTaxFree: product.isTaxFree,
			netPrice: product.netPrice,
			costUnit: product.costUnit ?? "",
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Ein Titel wird benötigt."),
			netPrice: Yup.number().required(),
			isHidden: Yup.boolean(),
			costUnit: Yup.string().optional(),
		}),
		onSubmit: (values: AccountGroupProductFormState, { setSubmitting }) => {
			editProduct({
				variables: {
					input: {
						id: product.id,
						netPrice: values.netPrice,
						isTaxFree: values.isTaxFree,
						title: values.title,
						costUnit: values.costUnit,
					},
				},
			});
			setSubmitting(false);
		},
	});

	return (
		<>
			<Toast ref={toast} />
			<Suspense fallback={<ProgressSpinner />}>
				<ProductForm
					formik={formik}
					handleSubmit={formik.handleSubmit}
					disabledFields={["isHidden"]}
				>
					<ValidatedField<PlacementProductFormState, string>
						name={"costUnit"}
						label="Kostenstelle"
						component={DefaultTextFieldComponent}
						formikConfig={formik}
					/>
				</ProductForm>
			</Suspense>
		</>
	);
};
