/**
 * @generated SignedSource<<6fa025e4b178db7aa0990d3fc7d2f6b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type accountGroupProductForm_AccountGroupProductFragment$data = {
  readonly accountGroupId: string | null | undefined;
  readonly costUnit: string | null | undefined;
  readonly id: string;
  readonly isHidden: boolean;
  readonly isTaxFree: boolean;
  readonly netPrice: any;
  readonly title: string;
  readonly " $fragmentType": "accountGroupProductForm_AccountGroupProductFragment";
};
export type accountGroupProductForm_AccountGroupProductFragment$key = {
  readonly " $data"?: accountGroupProductForm_AccountGroupProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"accountGroupProductForm_AccountGroupProductFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "accountGroupProductForm_AccountGroupProductFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isHidden",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTaxFree",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "netPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountGroupId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costUnit",
      "storageKey": null
    }
  ],
  "type": "AccountGroupProduct",
  "abstractKey": null
};

(node as any).hash = "c59be36729c1274d101a23a123d16642";

export default node;
